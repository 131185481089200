import { useTranslation } from 'react-i18next';
import {
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '@caspeco/casper-ui-library.components.modal';
import { Heading } from '@caspeco/casper-ui-library.components.heading';
import {
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { HistorySkeleton } from 'components/skeletons/SkeletonHistory';
import { useHistoryRequests } from 'components/history-modal/hooks/useHistoryRequests';
import { useBSTLUserStore } from 'store/bstlUserStore';
import { useHistoryStore } from 'store/historyStore';
import { Box } from '@caspeco/casper-ui-library.components.box';
import { Icons } from '@caspeco/casper-ui-library.components.icon';
import NotFound from 'components/not-found/NotFound';
import { HistoryCard } from './subcomponents/HistoryCard';

interface IHistoryModalList {
  onClickOrder: (orderId: string, isProcessed: boolean) => void;
}

export function HistoryModalList({ onClickOrder }: IHistoryModalList) {
  const { isLoading, error } = useHistoryRequests();
  const { t } = useTranslation();
  const { bstlUser } = useBSTLUserStore();
  const { ongoingOrders, processedOrders } = useHistoryStore();

  // When signed in as bstl user the guest can see both ongoing and processed orders
  const isEmpty = bstlUser
    ? !ongoingOrders?.length && !processedOrders?.length
    : ongoingOrders?.length === 0;

  let modalContent;

  if (isLoading)
    modalContent = (
      <ModalBody px={ThemeSpaceVariable.XLarge} py={ThemeSpaceVariable.Large}>
        <HistorySkeleton />
      </ModalBody>
    );
  else if (error) {
    modalContent = (
      <ModalBody minH={{ base: '300px', md: '400px' }}>
        <NotFound
          icon={Icons.Receipt}
          variant="surface"
          title={t('error_get_orders')}
          description={t('error_generic_reload_page')}
        />
      </ModalBody>
    );
  } else if (isEmpty) {
    modalContent = (
      <ModalBody minH={{ base: '300px', md: '400px' }}>
        <NotFound
          icon={Icons.Receipt}
          variant="surface"
          title={t('order_history_empty')}
          description={t('order_history_empty_description')}
        />
      </ModalBody>
    );
  } else {
    modalContent = (
      <ModalBody
        px={{ base: ThemeSpaceVariable.Large, md: ThemeSpaceVariable.XLarge }}
        py={ThemeSpaceVariable.Large}
      >
        {ongoingOrders.length > 0 && (
          <Box mb={ThemeSpaceVariable.XLarge}>
            <Heading
              as="h3"
              color={ThemeColorVariable.OnSurfaceHeader}
              fontSize={ThemeFontSizeVariable.XLarge}
            >
              {t('order_history_ongoing')}
            </Heading>
            {ongoingOrders.map((order) => (
              <HistoryCard
                onClick={onClickOrder}
                key={order.orderId}
                item={order}
              />
            ))}
          </Box>
        )}
        {bstlUser && processedOrders.length > 0 && (
          <>
            <Heading
              as="h3"
              color={ThemeColorVariable.OnSurfaceHeader}
              fontSize={ThemeFontSizeVariable.XLarge}
            >
              {t('history')}
            </Heading>
            {processedOrders.map((order) => (
              <HistoryCard
                key={order.orderId}
                onClick={onClickOrder}
                item={order}
                isProcessed
              />
            ))}
          </>
        )}
      </ModalBody>
    );
  }

  return (
    <>
      <ModalHeader
        display="flex"
        justifyContent="space-between"
        p={ThemeSpaceVariable.Medium}
      >
        <Heading
          as="h2"
          fontSize={ThemeFontSizeVariable.XLarge}
          fontWeight="medium"
          color={ThemeColorVariable.OnSurfaceHeader}
        >
          {t('history')}
        </Heading>
        <ModalCloseButton />
      </ModalHeader>
      {modalContent}
    </>
  );
}
