import { PriceIncludingVat } from 'types/price';

export enum NoOutcomeCode {
  CAMPAIGN_CODE_INVALID = 'CAMPAIGN_CODE_INVALID',
  CAMPAIGN_CODE_EXPIRED = 'CAMPAIGN_CODE_EXPIRED',
  CAMPAIGN_CODE_ALREADY_USED = 'CAMPAIGN_CODE_ALREADY_USED',
  CAMPAIGN_LOWER_AMOUNT_THAN_REQUIRED = 'CAMPAIGN_LOWER_AMOUNT_THAN_REQUIRED',
  CAMPAIGN_NOT_ACTIVE = 'CAMPAIGN_NOT_ACTIVE',
  CAMPAIGN_TIER_NOT_MET = 'CAMPAIGN_TIER_NOT_MET',
  NOT_HIGHEST_DISCOUNT_OUTCOME = 'NOT_HIGHEST_DISCOUNT_OUTCOME',
  CAMPAIGN_INVALID_ARTICLE_COMBINATION = 'CAMPAIGN_INVALID_ARTICLE_COMBINATION',
  CAMPAIGN_NOT_WITHIN_PERIOD = 'CAMPAIGN_NOT_WITHIN_PERIOD',
}

interface INoOutcomeValues {
  campaignId: string;
  offerId: string;
  code: string[];
}

interface ICampaignCodeValues {
  code: string;
  description: string;
  valid: boolean;
  validationError: string | null;
}

export interface ICampaignDiscountValues {
  name: string;
  discountAmount: PriceIncludingVat;
  code: string;
}

export interface ICampaignsValues {
  codes: ICampaignCodeValues[];
  discounts: ICampaignDiscountValues[];
  noOutcome: INoOutcomeValues[];
}

export class CampaignCode {
  code: string;
  description: string;
  valid: boolean;
  validationError: string | null;

  constructor(values: ICampaignCodeValues) {
    this.code = values?.code ?? '';
    this.description = values?.description ?? '';
    this.valid = values?.valid ?? false;
    this.validationError = values?.validationError ?? '';
  }
}

export class CampaignDiscount {
  name: string;
  discountAmount: PriceIncludingVat;
  code: string;

  constructor(values: ICampaignDiscountValues) {
    this.name = values?.name ?? '';
    this.discountAmount = new PriceIncludingVat(values?.discountAmount);
    this.code = values?.code ?? '';
  }
}

export class NoOutcome {
  campaignId: string;
  offerId: string;
  // This is an array in the backend API, but the UI can only handle one code at a time
  code: NoOutcomeCode;

  constructor(values: INoOutcomeValues) {
    this.campaignId = values?.campaignId ?? '';
    this.offerId = values?.offerId ?? '';
    this.code = (values?.code[0] as NoOutcomeCode) ?? '';
  }
}

export class Campaigns {
  codes: CampaignCode[];
  discounts: CampaignDiscount[];
  noOutcome: NoOutcome[];

  constructor(values: ICampaignsValues) {
    this.codes = values?.codes?.map((c) => new CampaignCode(c)) ?? [];
    this.discounts =
      values?.discounts?.map((d) => new CampaignDiscount(d)) ?? [];
    this.noOutcome = values?.noOutcome?.map((n) => new NoOutcome(n)) ?? [];
  }
}
