import { useTranslation } from 'react-i18next';
import { Box } from '@caspeco/casper-ui-library.components.box';
import { Heading } from '@caspeco/casper-ui-library.components.heading';
import {
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeFontVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Text } from '@caspeco/casper-ui-library.components.text';
import { Stack } from '@caspeco/casper-ui-library.components.stack';
import { getDeliveryOptionBreadCrumb } from 'helpers/deliveryoptions-helper/deliveryoptions-helper';
import { useOrderStore } from 'store/orderStore';
import { formatRelativeTimeAndDayWithLocale } from 'helpers/time-helper/time-helper';
import { useBaseColorValue } from 'components/base-color-context/BaseColorContext';

export default function OrderDetails() {
  const { t } = useTranslation();
  const { order } = useOrderStore();
  const { baseColor } = useBaseColorValue();

  let textColor;
  let headingColor;
  switch (baseColor) {
    case 'background':
      textColor = ThemeColorVariable.OnBackground;
      headingColor = ThemeColorVariable.OnBackgroundHeader;
      break;
    case 'surface':
    default:
      textColor = ThemeColorVariable.OnSurface;
      headingColor = ThemeColorVariable.OnSurfaceHeader;
      break;
  }

  return (
    <Box as="section" color={textColor} mt={ThemeSpaceVariable.Large}>
      <Stack spacing={ThemeSpaceVariable.Small}>
        <Heading
          as="h3"
          fontWeight="medium"
          fontFamily={ThemeFontVariable.SubHeader}
          color={headingColor}
          fontSize={ThemeFontSizeVariable.Large}
        >
          {t('order_details')}
        </Heading>
        {order?.deliveryOptionType && (
          <Box display="flex" justifyContent="space-between">
            <Text>{t('delivery_option')}</Text>
            <Text ml={ThemeSpaceVariable.Small}>
              {getDeliveryOptionBreadCrumb(
                order?.deliveryOptionType,
                order?.deliveryOptionName,
              )}
            </Text>
          </Box>
        )}
        {order?.placeName && (
          <Box display="flex" justifyContent="space-between">
            <Text>{t('location')}</Text>
            <Text ml={ThemeSpaceVariable.Small}>{order?.placeName} </Text>
          </Box>
        )}
        {order?.message && (
          <Box display="flex" justifyContent="space-between">
            <Text>{t('messages_message')}</Text>
            <Text
              wordBreak="break-word"
              ml={ThemeSpaceVariable.Small}
              align="right"
            >
              {order?.message}
            </Text>
          </Box>
        )}
        {order?.paidAt && (
          <Box display="flex" justifyContent="space-between">
            <Text>{t('order_ordered')}</Text>
            <Text ml={ThemeSpaceVariable.Small}>
              {formatRelativeTimeAndDayWithLocale(
                order.paidAt.toString(),
                order.placeTimezone,
              )}
            </Text>
          </Box>
        )}
      </Stack>
    </Box>
  );
}
