import { RouteObject } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@caspeco/casper-ui-library.components.box';
import { ThemeSpaceVariable } from '@caspeco/casper-ui-library.base-ui.theme';
import NotFound from 'components/not-found/NotFound';
import RootLayout from '../rootLayout';
import PlacesRoute from './places-route/PlacesRoute';

function ErrorElement() {
  const { t } = useTranslation();
  return (
    <Box px={ThemeSpaceVariable.Medium}>
      <NotFound
        title={t('error_generic_reload_page')}
        description=""
        showWithButton
      />
    </Box>
  );
}

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        path: ':appAlias/appredirectinfo',
        errorElement: <ErrorElement />,
        async lazy() {
          const AppRedirectInfoRoute = await import(
            './app-redirect-info-route/AppRedirectInfoRoute'
          );
          return { Component: AppRedirectInfoRoute.default };
        },
      },
      {
        path: ':appAlias',
        errorElement: <ErrorElement />,
        element: <PlacesRoute />,
      },
      {
        path: ':appAlias/links/:linkId',
        errorElement: <ErrorElement />,
        async lazy() {
          const LinksRoute = await import('./links-route/LinksRoute');
          return { Component: LinksRoute.default };
        },
      },
      {
        path: ':appAlias/cookie-policy',
        errorElement: <ErrorElement />,
        async lazy() {
          const CookiePolicyRoute = await import(
            './cookie-policy-route/CookiePolicyRoute'
          );
          return { Component: CookiePolicyRoute.default };
        },
      },
      {
        path: ':appAlias/cookie-list',
        errorElement: <ErrorElement />,
        async lazy() {
          const CookieListRoute = await import(
            './cookie-list-route/CookieListRoute'
          );
          return { Component: CookieListRoute.default };
        },
      },
      {
        path: ':appAlias/:placeId/menus',
        async lazy() {
          const MenusRoute = await import('./menus-route/MenusRoute');
          return { Component: MenusRoute.default };
        },
      },
      {
        path: ':appAlias/:placeId',
        errorElement: <ErrorElement />,
        async lazy() {
          const PlaceRoute = await import('./place-route/PlaceRoute');
          return { Component: PlaceRoute.default };
        },
      },
      {
        path: ':appAlias/:placeId/:menuId',
        errorElement: <ErrorElement />,
        async lazy() {
          const MenuRoute = await import('./menu-route/MenuRoute');
          return { Component: MenuRoute.default };
        },
      },
      {
        path: ':appAlias/:placeId/:menuId/checkout',
        errorElement: <ErrorElement />,
        async lazy() {
          const CheckoutRoute = await import('./checkout-route/CheckoutRoute');
          return { Component: CheckoutRoute.default };
        },
      },
      {
        path: ':appAlias/checkout/:orderId',
        errorElement: <ErrorElement />,
        async lazy() {
          const OrderConfirmationRoute = await import(
            './order-confirmation-route/OrderConfirmationRoute'
          );
          return { Component: OrderConfirmationRoute.default };
        },
      },
      {
        path: ':appAlias/confirmEmail',
        errorElement: <ErrorElement />,
        async lazy() {
          const ConfirmEmailRoute = await import(
            './confirm-email/ConfirmEmailRoute'
          );
          return { Component: ConfirmEmailRoute.default };
        },
      },
    ],
  },
];
