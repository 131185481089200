import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import PlaceSkeleton from 'components/skeletons/PlaceSkeleton';
import SkeletonMenusList from 'components/skeletons/SkeletonMenusList';
import { getAppAlias } from 'helpers/app-helper/app-helper';
import { SkeletonPlacesList } from 'components/skeletons/SkeletonPlacesList';
import { SkeletonMenu } from 'components/skeletons/SkeletonMenu';
import { SkeletonOrderConfirmation } from 'components/skeletons/SkeletonOrderConfirmation';
import { SkeletonCheckout } from 'components/skeletons/SkeletonCheckout';
import { HistorySkeleton } from 'components/skeletons/SkeletonHistory';
import { ProtectedRoutes } from 'types/routes';
import NotFound from 'components/not-found/NotFound';
import { useProtectedRouteRequests } from './useProtectedRouteRequests';

interface IProtectedRoute {
  children: ReactElement;
  route: ProtectedRoutes;
}

function ProtectedRoute({ children, route }: IProtectedRoute) {
  const { isLoading, isAuthenticated, error } = useProtectedRouteRequests();
  const appAlias = getAppAlias();

  let skeleton;
  switch (route) {
    case ProtectedRoutes.PLACES:
      skeleton = <SkeletonPlacesList />;
      break;
    case ProtectedRoutes.PLACE:
      skeleton = <PlaceSkeleton />;
      break;
    case ProtectedRoutes.MENU:
      skeleton = <SkeletonMenu />;
      break;
    case ProtectedRoutes.MENUS:
      skeleton = <SkeletonMenusList />;
      break;
    case ProtectedRoutes.ORDER_CONFIRMATION:
      skeleton = <SkeletonOrderConfirmation />;
      break;
    case ProtectedRoutes.CHECKOUT:
      skeleton = <SkeletonCheckout />;
      break;
    case ProtectedRoutes.HISTORY:
      skeleton = <HistorySkeleton />;
      break;
    default:
      skeleton = <></>;
      break;
  }

  switch (true) {
    case Boolean(error):
      return <NotFound showWithButton />;
    case isLoading:
      return skeleton;
    case isAuthenticated:
      return children;
    default:
      // No token found, redirect to home route
      return <Navigate to={{ pathname: `/${appAlias}` }} />;
  }
}

export default ProtectedRoute;
