import { Box } from '@caspeco/casper-ui-library.components.box';
import {
  ThemeColorVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { CSSVarHeaderHeight } from 'components/global-styles/GlobalStyles';
import Container from 'components/container/Container';

/** Skeleton representation of <PageWrapper/> */
interface ISkeletonPageWrapper {
  children?: React.ReactNode;
}
export function SkeletonPageWrapper({ children }: ISkeletonPageWrapper) {
  return (
    <>
      <span data-testid="loading-indicator" />
      <Box h={CSSVarHeaderHeight} bg={ThemeColorVariable.Background} />
      <Box
        bg={ThemeColorVariable.Background}
        display="flex"
        flexGrow="1"
        flexShrink="0"
        flexBasis="auto"
        p={ThemeSpaceVariable.Large}
      >
        <Container>{children}</Container>
      </Box>
    </>
  );
}
