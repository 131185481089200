enum EventType {
  EVENT = 'event',
}

enum Component {
  CHECKOUT = 'checkout',
}

enum Action {
  CLICK = 'click',
  NAVIGATION = 'navigation',
}

enum ActionDetail {
  BACK_WITH_TIP = 'backWithTip',
  TIP_BUTTON = 'tipButton',
}

type EventName =
  | `${EventType}.${Component}.${Action}`
  | `${EventType}.${Component}.${Action}.${ActionDetail}`;

interface IAnalyticsEventItem {
  key: string;
  value: string;
}

export interface IAnalyticsEvent {
  eventName: EventName;
  items: IAnalyticsEventItem[];
}

// Base class, every specific event to track should extend this
class AnalyticsEvent implements IAnalyticsEvent {
  items: IAnalyticsEventItem[];

  constructor(
    public eventName: EventName,
    items: IAnalyticsEventItem[],
  ) {
    this.items = items;
  }
}

/**
 * Analytics Event to track when the user clicks on a tip button in Checkout.
 */
export class CheckoutClickTipButton extends AnalyticsEvent {
  constructor(
    active: boolean,
    buttonIndex: number,
    percent: number | undefined,
  ) {
    super(
      `${EventType.EVENT}.${Component.CHECKOUT}.${Action.CLICK}.${ActionDetail.TIP_BUTTON}`,
      [
        { key: 'active', value: String(active) },
        { key: 'buttonIndex', value: String(buttonIndex) },
        { key: 'percent', value: percent ? String(percent) : '' },
      ],
    );
  }
}

/**
 * Analytics Event to track when the user navigates backwards from Checkout
 * and have added a tip to their cart.
 */
export class CheckoutNavigationBackWithTip extends AnalyticsEvent {
  constructor() {
    super(
      `${EventType.EVENT}.${Component.CHECKOUT}.${Action.NAVIGATION}.${ActionDetail.BACK_WITH_TIP}`,
      [],
    );
  }
}
