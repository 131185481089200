import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@caspeco/casper-ui-library.components.drawer';
import { Icons } from '@caspeco/casper-ui-library.components.icon';
import { ProtectedRoutes } from 'types/routes';
import { IconButton } from '@caspeco/casper-ui-library.components.icon-button';
import { SideNavigation } from 'components/side-navigation/SideNavigation';

export function HamburgerButton({ routeName }: { routeName: ProtectedRoutes }) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <SideNavigation
        route={routeName}
        isSideNavOpen={isOpen}
        onCloseSideNav={onClose}
      />
      <IconButton
        aria-label={t('action_show_more')}
        onClick={onOpen}
        icon={Icons.Menu}
        size={{
          base: 'md',
          md: 'lg',
        }}
      />
    </>
  );
}
