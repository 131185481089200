import { PlacesProvider } from 'store/placesStore';
import PlacesPage from 'routes/places-route/components/places/Places';
import { ProtectedRoutes } from 'types/routes';
import RouteWrapper from 'components/route-wrapper/RouteWrapper';
import ProtectedRoute from 'components/protected-route/ProtectedRoute';

function PlacesRoute() {
  return (
    <PlacesProvider>
      <RouteWrapper route={ProtectedRoutes.PLACES}>
        <ProtectedRoute route={ProtectedRoutes.PLACES}>
          <PlacesPage />
        </ProtectedRoute>
      </RouteWrapper>
    </PlacesProvider>
  );
}

export default PlacesRoute;
