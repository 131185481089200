import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { SupportedLanguage } from 'types/language';
import svTranslation from '../translations/sv/translation.json';
import enTranslation from '../translations/en/translation.json';
import nbTranslation from '../translations/nb/translation.json';

export const resources = {
  en: {
    translation: enTranslation,
  },
  sv: {
    translation: svTranslation,
  },
  nb: {
    translation: nbTranslation,
  },
} as const;

i18next
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['localStorage', 'navigator'],
    },
    supportedLngs: [
      SupportedLanguage.SV,
      SupportedLanguage.NB,
      SupportedLanguage.EN,
    ],
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      formatSeparator: ',',
      escapeValue: false,
    },
    resources,
  });

const i18n = i18next;

export default i18n;
