import { AppFeatures, IAppFeaturesValues } from './appFeatures';
import { ImageMap, ImageMapValues } from './imageMap';
import { IThemeValues, Theme } from './theme';

type AppPlatform = 'bstl-ios' | 'bstl-android' | 'bstl-web';

interface IAppImagesValues {
  logotypeOnImage: ImageMapValues | null;
  logotypeOnPrimary: ImageMapValues | null;
  cover: ImageMapValues | null;
  icon: ImageMapValues | null;
}

export class AppImages {
  logotypeOnImage: ImageMap | undefined;
  logotypeOnPrimary: ImageMap | undefined;
  cover: ImageMap | undefined;
  icon: ImageMap | undefined;

  constructor(values?: IAppImagesValues) {
    this.logotypeOnImage = values?.logotypeOnImage
      ? new ImageMap(values?.logotypeOnImage)
      : undefined;
    this.logotypeOnPrimary = values?.logotypeOnPrimary
      ? new ImageMap(values?.logotypeOnPrimary)
      : undefined;
    this.cover = values?.cover ? new ImageMap(values?.cover) : undefined;
    this.icon = values?.icon ? new ImageMap(values?.icon) : undefined;
  }
}

export interface IAppResponse {
  appId: string;
  appName: string;
  appAlias: string;
  images?: IAppImagesValues;
  theme: IThemeValues;
  features: IAppFeaturesValues;
  links: IAppLinks;
  firebaseConfig: IFirebaseConfig;
  /** To be @deprecated and replaced with icon (same data) within appImages */
  icon: ImageMapValues;
  platforms: string[];
}

export class AppData {
  appId: string;
  appName: string;
  appAlias: string;
  images: AppImages;
  theme: Theme;
  features: AppFeatures;
  links: IAppLinks;
  firebaseConfig: IFirebaseConfig;
  /** To be @deprecated and replaced with icon (same data) within images */
  icon: ImageMap;
  platforms: AppPlatform[];

  constructor(values?: IAppResponse) {
    this.appId = values?.appId ?? '';
    this.appName = values?.appName ?? '';
    this.appAlias = values?.appAlias ?? '';
    this.images = new AppImages(values?.images);
    this.theme = new Theme(values?.theme);
    this.features = new AppFeatures(values?.features?.general);
    this.links = {
      privacyPolicyURL: values?.links?.privacyPolicyURL ?? '',
      termsAndConditionsURL: values?.links?.termsAndConditionsURL ?? '',
      allergyInfoURL: values?.links?.allergyInfoURL ?? '',
      appStoreURL: values?.links?.appStoreURL ?? '',
      googlePlayURL: values?.links?.googlePlayURL ?? '',
    };
    this.firebaseConfig = values?.firebaseConfig ?? {
      apiKey: '',
      authDomain: '',
      projectId: '',
      storageBucket: '',
      messagingSenderId: '',
      appId: '',
    };
    this.icon = new ImageMap(values?.icon);
    this.platforms = (values?.platforms as AppPlatform[]) ?? [];
  }
}

export interface IAppLinks {
  privacyPolicyURL: string;
  termsAndConditionsURL: string;
  allergyInfoURL?: string;
  appStoreURL?: string;
  googlePlayURL?: string;
}

export interface IFirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

export interface IAppFeedbackRequest {
  emailAddress: string;
  subject: string;
  message: string;
  name: string;
}
