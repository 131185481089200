import { useTranslation } from 'react-i18next';
import { Box } from '@caspeco/casper-ui-library.components.box';
import { AspectRatio, BreakPoint } from 'types/ui';
import { useMinWidthMediaQuery } from 'hooks/useMediaQuery';
import {
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeRadiusVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import {
  Skeleton,
  SkeletonText,
} from '@caspeco/casper-ui-library.components.skeleton';

import { Heading } from '@caspeco/casper-ui-library.components.heading';
import { StyledDesktopCart } from 'components/cart/Cart.styles';
import { Divider } from '@caspeco/casper-ui-library.components.divider';
import { AspectRatioWrapper } from 'components/aspect-ratio-wrapper/AspectRatioWrapper';
import { GridWithDividers } from 'components/grid-with-dividers/GridWithDividers';
import { usePickSearchParams } from 'hooks/usePickSearchParams';
import { getLocalStorage } from 'helpers/local-storage-helper/local-storage-helper';
import { IAppGeneralFeatures } from 'types/appFeatures';
import { StyledArticleGrid } from 'routes/menu-route/components/menu-section/MenuSection.styles';

function SkeletonArticleCard() {
  return (
    <Box
      flexDirection="row"
      p={ThemeSpaceVariable.Small}
      bg={ThemeColorVariable.Background}
      justifyContent="space-between"
      alignItems="center"
      display="flex"
    >
      <Box
        display="flex"
        flexDirection="column"
        w="70%"
        mx={ThemeSpaceVariable.Small}
      >
        <Skeleton
          h={{
            base: ThemeFontSizeVariable.Large,
            md: ThemeFontSizeVariable.XLarge,
          }}
          w="25%"
          mb={ThemeSpaceVariable.Small}
        />
        <SkeletonText
          noOfLines={2}
          skeletonHeight={ThemeFontSizeVariable.Medium}
        />
      </Box>
      <Skeleton
        startColor={ThemeColorVariable.BackgroundDisabled}
        endColor={ThemeColorVariable.OnBackgroundBorder}
        h="5.5rem"
        w="5.5rem"
        borderRadius={ThemeRadiusVariable.Medium}
      />
    </Box>
  );
}

function SkeletonArticles() {
  return (
    <>
      <Box h="80px" w="100%" />
      <Box w="100%" pl={ThemeSpaceVariable.Small}>
        <Divider
          w="100%"
          orientation="horizontal"
          borderColor={ThemeColorVariable.OnBackgroundBorder}
        />
      </Box>
      <Box display="flex" flexDirection="column" w="100%">
        <StyledArticleGrid>
          {[...Array(12)].map((_item, index) => (
            <SkeletonArticleCard key={index} />
          ))}
        </StyledArticleGrid>
      </Box>
    </>
  );
}

function SkeletonCart() {
  const { t } = useTranslation();
  return (
    <StyledDesktopCart>
      <Box pt={ThemeSpaceVariable.Medium} px={ThemeSpaceVariable.Medium}>
        <Heading
          as="h2"
          fontWeight="medium"
          fontSize={ThemeFontSizeVariable.XLarge}
          color={ThemeColorVariable.OnSurfaceHeader}
        >
          {t('purchase_cart_your')}
        </Heading>
      </Box>
    </StyledDesktopCart>
  );
}

function SkeletonSectionGrid() {
  let columnCount = 2;
  const isLargerThanLargeBreakPoint = useMinWidthMediaQuery(BreakPoint.Large);
  const isLargerThanXLargeBreakPoint = useMinWidthMediaQuery(BreakPoint.XLarge);
  if (isLargerThanLargeBreakPoint) columnCount = 3;
  if (isLargerThanXLargeBreakPoint) columnCount = 4;
  return (
    <Box p={ThemeSpaceVariable.Medium}>
      <GridWithDividers
        columnCount={columnCount}
        renderGridItem={() => (
          <>
            <AspectRatioWrapper aspectratio={AspectRatio.OneByOne}>
              <Skeleton minW="100%" />
            </AspectRatioWrapper>
            <SkeletonText
              mt={ThemeSpaceVariable.Small}
              noOfLines={1}
              skeletonHeight={[
                ThemeFontSizeVariable.Medium,
                ThemeFontSizeVariable.XLarge,
              ]}
            />
          </>
        )}
        items={[...Array(6)]}
      />
    </Box>
  );
}

export function SkeletonMenu() {
  const { showMenuSectionList } = usePickSearchParams(['showMenuSectionList']);
  const supportsShowMenuSectionListFeature =
    getLocalStorage<IAppGeneralFeatures>('appFeatures')?.showMenuSectionList;

  const isLargerScreen = useMinWidthMediaQuery(BreakPoint.Medium);
  const isShowingMenuSectionList =
    supportsShowMenuSectionListFeature && showMenuSectionList;

  return (
    <span data-testid="loading-indicator">
      <Box display="flex" flexDirection="row">
        <Box w={{ base: '100%', md: '70%' }} display="inline-flex">
          <Box w="100%" pr={ThemeSpaceVariable.Small}>
            {isShowingMenuSectionList ? (
              <SkeletonSectionGrid />
            ) : (
              <SkeletonArticles />
            )}
          </Box>
        </Box>
        {isLargerScreen && <SkeletonCart />}
      </Box>
    </span>
  );
}
