import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@caspeco/casper-ui-library.components.modal';
import { Image } from '@caspeco/casper-ui-library.components.image';
import { Box } from '@caspeco/casper-ui-library.components.box';
import {
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeFontVariable,
  ThemeIconSizeVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Text } from '@caspeco/casper-ui-library.components.text';
import { Heading } from '@caspeco/casper-ui-library.components.heading';
import { Stack } from '@caspeco/casper-ui-library.components.stack';
import { Divider } from '@caspeco/casper-ui-library.components.divider';
import { Icon, Icons } from '@caspeco/casper-ui-library.components.icon';
import { Button } from '@caspeco/casper-ui-library.components.button';
import { usePlaceStore } from 'store/placeStore';
import { AspectRatio } from 'types/ui';
import PlaceContactInfo from 'components/place-contact-info/PlaceContactInfo';
import OpeningHours from 'components/opening-hours/OpeningHours';

export default function PlaceModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { place } = usePlaceStore();
  const { t } = useTranslation();
  const onReload = () => window.location.reload();

  if (!place)
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalHeader p={ThemeSpaceVariable.Medium}>
          {t('error_generic')}
        </ModalHeader>
        <ModalBody
          px={ThemeSpaceVariable.Medium}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={ThemeSpaceVariable.Medium}
        >
          <Icon
            icon={Icons.SlightlySad}
            size={ThemeIconSizeVariable.X2Large}
            color={ThemeColorVariable.OnSurfaceBorder}
          />
          <Text align="center">{t('error_generic_reload_page')}</Text>
        </ModalBody>
        <ModalFooter
          pt={ThemeSpaceVariable.Medium}
          px={ThemeSpaceVariable.Medium}
          pb="calc(1em + env(safe-area-inset-bottom))"
          justifyContent="center"
        >
          <Button size={{ base: 'lg', md: 'md' }} onClick={onReload}>
            {t('action_fetch_again')}
          </Button>
        </ModalFooter>
      </Modal>
    );

  const {
    description,
    name,
    imageMap,
    address,
    openingHours,
    timeZoneName,
    phone,
    corporateId,
    email,
  } = place;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalBody>
        {imageMap.hasImage(AspectRatio.SixteenByNine) && (
          <Image
            src={imageMap.withSizeQuery(AspectRatio.SixteenByNine, 'l')}
            srcSet={imageMap.getScrSet(AspectRatio.SixteenByNine, 'l', 'xl')}
            alt={t('misc_picture_of_x', { 0: place?.name })}
          />
        )}
        <ModalCloseButton isAbsolutePositioned={true} />
        <Box p={ThemeSpaceVariable.Medium}>
          <Stack
            divider={
              <Divider
                borderColor={ThemeColorVariable.OnSurfaceBorder}
                my={ThemeSpaceVariable.Large}
              />
            }
            spacing={ThemeSpaceVariable.Medium}
          >
            <Box>
              <Heading
                color={ThemeColorVariable.OnSurfaceHeader}
                as="h2"
                fontFamily={ThemeFontVariable.SubHeader}
                fontWeight="medium"
                mb={ThemeSpaceVariable.XSmall}
              >
                {name}
              </Heading>
              <Text
                color={ThemeColorVariable.OnSurface}
                fontSize={ThemeFontSizeVariable.Large}
              >{`${address.streetAddress}, ${address.city}`}</Text>
            </Box>
            <OpeningHours
              headerColor={ThemeColorVariable.OnSurfaceHeader}
              openingHours={openingHours}
              timeZoneName={timeZoneName}
            />
            {description && (
              <Box as="section">
                <Heading
                  as="h3"
                  fontFamily={ThemeFontVariable.SubHeader}
                  color={ThemeColorVariable.OnSurfaceHeader}
                  fontWeight="medium"
                  fontSize={ThemeFontSizeVariable.Large}
                  mb={ThemeSpaceVariable.XSmall}
                >
                  {t('misc_about_x', { 0: name })}
                </Heading>
                <Text color={ThemeColorVariable.OnSurface}>{description}</Text>
              </Box>
            )}
            {place && (
              <PlaceContactInfo
                headerColor={ThemeColorVariable.OnSurfaceHeader}
                phone={phone}
                email={email}
                corporateId={corporateId}
              />
            )}
          </Stack>
        </Box>
      </ModalBody>
    </Modal>
  );
}
