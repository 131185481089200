import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { Text } from '@caspeco/casper-ui-library.components.text';
import {
  ThemeColorVariable,
  ThemeFontSizeVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { TStep } from '@caspeco/casper-ui-library.components.stepper';
import { Box } from '@caspeco/casper-ui-library.components.box';
import { DeliveryStatus } from 'types/order';
import { PaymentStatus } from 'types/payment';
import { useBaseColorValue } from 'components/base-color-context/BaseColorContext';

export const RecievedStep = ({
  awaitingPaymentConfirmation,
  paidAt,
  deliveryStatus,
  status,
}: {
  awaitingPaymentConfirmation: boolean;
  paidAt: string | undefined;
  deliveryStatus: DeliveryStatus | undefined;
  status: PaymentStatus | undefined;
}): TStep => {
  const { t } = useTranslation();
  const { baseColor } = useBaseColorValue();

  let labelTextColor;
  let paidAtTextColor;
  let defaultDividerColor;
  switch (baseColor) {
    case 'background':
      labelTextColor = ThemeColorVariable.OnBackgroundHeader;
      paidAtTextColor = ThemeColorVariable.OnBackgroundSubdued;
      defaultDividerColor = ThemeColorVariable.OnBackgroundBorder;
      break;
    case 'surface':
    default:
      labelTextColor = ThemeColorVariable.OnSurfaceHeader;
      paidAtTextColor = ThemeColorVariable.OnSurfaceSubdued;
      defaultDividerColor = ThemeColorVariable.OnSurfaceBorder;
      break;
  }

  return {
    label: awaitingPaymentConfirmation ? (
      <Text color={labelTextColor}>
        {t('order_status_awaiting_confirmation')}
      </Text>
    ) : (
      <Box display="flex" alignItems="center" gap="4px">
        <Text
          fontWeight="medium"
          color={labelTextColor}
          fontSize={ThemeFontSizeVariable.Medium}
        >
          {t('order_status_received')}
        </Text>
        {paidAt && (
          <Text color={paidAtTextColor} fontSize={ThemeFontSizeVariable.Medium}>
            {DateTime.fromISO(paidAt).toFormat('HH:mm')}
          </Text>
        )}
      </Box>
    ),
    color: ThemeColorVariable.Success,
    iconColor: ThemeColorVariable.OnSuccess,
    dividerColor:
      deliveryStatus !== 'NEW' || status === 'REFUNDED' || status === 'CANCELED'
        ? ThemeColorVariable.Success
        : defaultDividerColor,
    icon: awaitingPaymentConfirmation ? 'spinner' : 'check',
  };
};
